@import "../utils/reset";

%tweets {
	ul {
		@include clear-ul;
	}
	
	.user {
		display: block;
		color: inherit;
		margin: 0 0 1em;
		text-decoration: none;
		padding: .2em 0;
	}

	.profile {
		float: left;
		margin: 0 .6em 0 0;
		width: 48px;
		margin-top: -.2em;
	}

	.name, .handle {
		display: block;
	}

	.name {
		font-weight: 700;
	}

	.handle {
		margin-top: -.1em;
		opacity: .6;
	}
	
	.text {
		clear: both;
	}
	
	time {
		display: block;
		font-size: .9em;
		opacity: .6;
	}	
}