@import "../../euphoria/social/twitter";

.twitter-feed {
	font-size: .79em;
	@extend %tweets;

	.tweet {
		margin-bottom: .8em;
	}

	.user {
		margin-bottom: .4em;
	}

	.profile {
		border-radius: 3px;
		margin-top: .3em;
		width: 32px;
	}
}
