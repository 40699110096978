#header-cta {
	background: #f3f4f6;
	border-bottom: 1px solid #e7e7e7;
	box-sizing: border-box;
	font-size: .79em;
	//height: 2.8em;
	line-height: 1;
	overflow: hidden;
	padding: .9em;
	text-align: right;
	position: relative;
	
	strong {
		color: $theme-primary-color;
	}
	
	a {
		color: $theme-secondary-color;
	}
}