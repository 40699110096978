@mixin clear-ul() {
	margin: 0;
	padding: 0;
	list-style: none;
	
	li {
		margin-bottom: 0;

		&:before, &:after {
			content: normal;
		}
	}
}