
$responsive-menu-side: left !default;
$responsive-menu-button-color: #666 !default;
$responsive-menu-button-active-color: red !default;
$responsive-menu-overlay-color: rgba( #000, .2 );

@mixin responsive-menu-overlay (
	$color: $responsive-menu-overlay-color
) {
	background: rgba($color, 0);
	display: block;
	width: 0; height: 0;
	z-index: 998;
	transition: background .24s;
	
	.euphoria-menu-active & {
		background: $color;
		position: fixed;
		top: 0; left: 0; right: 0; bottom: 0;
		width: auto;
		height: auto;
	}
	
	@include breakpoint( tablet-landscape, $property: min-width ) {
		display: none;
	}
}

@mixin responsive-menu (
	$side: $responsive-menu-side,
	$breakpoint: tablet-landscape
) {
	display: none;
	background: #333;
	color: #fff;
	position: fixed;
	top: 0;
	bottom: 0;
	left: -100%;
	width: 100%;
	max-width: 320px;
	opacity: 0;
	overflow-y: auto;
	z-index: 999;
	transform: translate3d( 0, 0, 0 );
	transition: left .3s, right .3s, transform .3s, opacity .3s;

	.cssanimations & {
		left: 0;
		transform: translate3d( -100%, 0, 0 );
	}

	@if $side == 'right' {
		left: auto;
		right: -100%;
		
		.cssanimations & {
			right: 0;
			left: auto;
			transform: translate3d( 100%, 0, 0 );
		}
	}
	
	@include breakpoint( $breakpoint ) {
		display: block;
	}

	&.active {
		left: 0;
		opacity: 1;
		
		.cssanimations & {
			transform: translate3d( 0, 0, 0 );
		}
	
		@if $side == 'right' {
			left:  auto;
			right: 0;
			
			.cssanimations & {
				transform: translate3d( 0, 0, 0 );
			}
		}
	}
}

@mixin responsive-menu-content () {
	ul {
		@include clear-ul;
	}

	ul {
		clear: both;
		list-style: none;
		padding: 0;
		margin: 0;
	}

	li {
		&.animated {
			position: relative;
			left: -1em;
			opacity: 0;
			transition: all .3s;

			.expand {
				background: rgba(#000, .5);
				color: #fff;
				display: block;
				position: absolute;
				top: 0;
				right: 0;
				height: 3em;
				width: 3em;
				//			background: transparent url(/live/images/menu-open-section.png) no-repeat center center !important;
				background-size: 16px auto !important;
				padding: 0;
				text-align: center;
				line-height: 3em;
				vertical-align: middle;
				transition: all .24s;

				&:before {
					content: '>';
				}
			}

			&.open {
				> .expand {
					transform: rotate(180deg);
				}

				> ul {
					display: block;
				}
			}

			&.visible {
				opacity: 1;
				left: 0;
			}
		}

		// Hide submenus
		ul {
			background: rgba( #000, 0.2 );
			display: none;

			li:first-child { padding-top: .8em; }
			li:last-child { padding-bottom: .8em; }
		}
	}

	a {
		display: block;
		padding: 1em 3em 1em 1.4em;
		line-height: 1;
		transition: all .3s;

		&:hover {
			background: rgba( #fff, .1 );
			text-decoration: none;
		}

		&:active {
			background: rgba( #000, .1 );
		}
	}

	.active > a {
		background: rgba( #fff, .20 );
	}
}

// A default style mixin for a hamburger button that transforms into a close button on click.
// Expects the following HTML structure:
// <elm> (the element the mixin is applied to)
// 		<span class="burger-top"></span>
//		<span class="burger-middle"></span>
//		<span class="burger-bottom"></span>
// </elm>
//
// @param string $open-class The class added to the <body> when the menu is open, close state is shown here.
// @param string $breakpoint The breakpoint to start showing the button
//
@mixin responsive-menu-button (
	$side: $responsive-menu-side,
	$breakpoint: tablet-landscape,
	$dimensions: (2.4em, 2.4em),
	$color: $responsive-menu-button-color,
	$active-color: $responsive-menu-button-active-color,
	$style: slanted
) {
	background: transparent;
	display: none;
	position: relative;
	z-index: 1001;
	width: nth($dimensions, 1);
	height: if( length($dimensions) > 1, nth($dimensions, 2), nth($dimensions, 1) );

	span {
		border-radius: 3px;
		content: '';
		display: block;
		background: $color;
		padding: 0;
		height: 2px;
		width: 100%;
		position: absolute;
		transition: all .24s;
		opacity: 1;
		left: 0;
		transform-origin: left;
		
		@if $side == 'right' {
			left: auto;
			right: 0;
			transform-origin: right;
		}
	}
	
	.burger-top {
		top: 20%;
	}
	
	.burger-middle {
		top: 46.5%;

		@if $style == slanted {
			width: 75%;
		}

	}
	
	.burger-bottom {
		bottom: 20%;

		@if $style == slanted {
			width: 50%;
		}
	}
	
	&.active {
		.burger-top, .burger-bottom {
			background: $active-color;
			width: 100%;
		}
		
		.burger-top {
			transform: translateY(-.2em) rotate(45deg);
			
			@if $side == 'right' {
				transform: translateY(-.2em) rotate(-45deg);
			}
		}
		
		.burger-middle {
			left: -50%;
			opacity: 0;
		}
		
		.burger-bottom {
			transform: translateY(.2em) rotate(-45deg);
			
			@if $side == 'right' {
				transform: translateY(.2em) rotate(45deg);
			}
		}
		
		@if $side == 'right' {
			.burger-middle {
				left: auto;
				right: -50%;
			}
		}
	}

	@include breakpoint( $breakpoint ) {
		display: block;
	}
	
	@content;
}
