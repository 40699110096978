$breakpoints: (
	desktop:				1280px,
	tablet-landscape:		1024px,
	tablet-vertical:		1023px,
	tablet-portrait:		768px,
	phone-landscape:		640px,
	phone-portrait:			480px,
	phone-small-portrait:	320px,
	watch: 					240px
) !default;

@mixin breakpoint ( $breakpoint, $property: max-width ) {
	@media only screen and ( unquote($property): map-get($breakpoints, $breakpoint) ) {
		@content;
	}
}

@mixin tablet-landscape {
	@include breakpoint( tablet-landscape ) {
		@content;
	}
}

@mixin tablet-portrait {
	@include breakpoint( tablet-portrait ) {
		@content;
	}
}

@mixin phone-landscape {
	@include breakpoint( phone-landscape ) {
		@content;
	}
}

@mixin phone-portrait {
	@include breakpoint( phone-portrait ) {
		@content;
	}
}

@mixin phone-small-portrait {
	@include breakpoint( phone-small-portrait ) {
		@content;
	}
}

@mixin watch {
	@include breakpoint( watch ) {
		@content;
	}
}

@mixin generate-breakpoint-classes ( $suffix, $breakpoints: $breakpoints ) {
	@each $breakpoint, $size in $breakpoints {
		.#{$breakpoint}-#{$suffix} {
			@include breakpoint( $breakpoint ) {
				@content;
			}
		}
	}
}
