@import url( "//fonts.googleapis.com/css?family=Montserrat:700,400|Source+Sans+Pro:400,400italic,700,300,300italic");
$theme-primary-color: #21698a;
$theme-secondary-color: #e25843;

$button-background: $theme-secondary-color;
$column-gutter: 2.4em;
$eresults-branding: true;
$font-color: #343434;
$font-family: 'Source Sans Pro', Arial, sans-serif;
$font-size: 19px;
$font-weight: normal;
$header-font-color: null;
$header-font-family: 'Montserrat', sans-serif;
$header-font-weight: 700;
$h1-font-color: $theme-primary-color;
$h1-font-weight: null;
$h2-font-color: $theme-secondary-color;
$h2-font-weight: null;
$h3-font-color: $theme-primary-color;
$h3-font-weight: null;
$h4-font-color: $theme-secondary-color;
$h4-font-weight: 400;
$h5-font-color: $theme-primary-color;
$h5-font-weight: null;
$h6-font-color: $theme-secondary-color;
$h6-font-weight: null;
$footer-background-color: #eee;
$footer-link-color: null;
$footer-cta-background-color: $theme-primary-color;
$footer-cta-link-color: #ffffff;
$footer-newsletter-background-color: $theme-primary-color;
$footer-newsletter-link-color: $footer-link-color;
$footer-header-font-color: #ffffff;
$logo-height: 1.6;
$menu-horizontal-spacing: null;
$menu-font-color: null;
$menu-font-weight: null;
$menu-active-font-color: null;
$menu-active-font-weight: null;
$fragment-spacing: 2em;
$link-color: $theme-secondary-color;
$list-ordered-icon-color: $theme-primary-color;
$list-unordered-icon-color: $theme-primary-color;
$list-unordered-checks-icon: url('../images/list-unordered-icon.png');


