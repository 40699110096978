.mod-google-map {
	border: 1px solid #e3e3e3;
	border-radius: 5px;

	.full-width & {
		border-bottom: 5px solid $theme-primary-color;
	}

	.boxed & {
		border-radius: 0;
		border-width: 0;
		margin: -1.5em;

		noscript .noscript-wrapper {
			padding: 1.5em;
		}

		&:not(:first-child) {
			margin-top: $element-vertical-spacing;
			border-top-width: 1px;
		}

		&:not(:last-child) {
			margin-bottom: $element-vertical-spacing;
			border-bottom-width: 1px;
		}
	}

	.map-container {
		min-height: 300px;
	}
}