.mod-images {
	ul {
		@include clear-ul;
	}

	&.element-template--default, &.element-template--2-overlapping {
		img {
			border-radius: 5px;
			max-width: 100%;
		}

		.fluid-columns & {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
			height: 100%;
			overflow: hidden;

			ul, li {
				width: 100%;
				height: 100%;
			}

			li:nth-child(n+2) {
				display: none;
			}

			img {
				border-radius: 0;
				position: absolute;
				left: 50%;
				max-height: 100%;
				max-width: none;
				min-height: 100%;
				top: 50%;
				transform: translateY(-50%) translateX(-50%);
			}
		}
	}

	&.element-template--2-overlapping {
		position: relative;

		li {
			width: 80%;

			&:first-child {
				padding: 0 20% 20% 0;
			}

			&:last-child {
				position: absolute;
				bottom: 0;
				right: 0;
			}
		}
	}
}
