.mod-text {
	&.element-template--default {

	}

	&.element-template--boxed {
		background: #f8f8f8;
		border: 1px solid #e1e0dd;
		border-radius: 3px;
		padding: 1em 1.567em;
	}
}
