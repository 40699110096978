@mixin vertical-menu {
	li, a {
		float: none !important;
		position: relative;
	}

	li {
		border: 1px solid #e5e5e5;
		border-width: 1px 0;
		margin-bottom: -1px;

		&.active > ul {
			display: block;
		}
	}

	a {
		padding: .505em 1.316em !important;
	}

	li ul {
		background: #f3f4f6;
		position: static;
		box-shadow: none;
		border: none;
		border-radius: 0;
	}

	li li {
		padding-left: .624em;
	}

	.expand {
		box-sizing: border-box;
		padding: 1.18em !important;
		position: absolute;
		top: 0;
		right: 0;
		height: 2.5em;
		width: 2.5em;
	}
}

.mod-menu {
	li {
		list-style: none !important;
	}

	a {
		@if $menu-font-color {
			color: $menu-font-color;
		} @else {
			color: inherit;
		}

		@if $menu-font-weight {
			font-weight: $menu-font-weight;
		}

		font-size: 1.05em;
		display: block;
		text-decoration: none;
		transition: color .14s;

		&:hover {
			color: $theme-secondary-color;
		}

		&.expand {
			color: $theme-primary-color;
			text-align: center;
			position: relative;

			&:before, &:after {
				background: $theme-primary-color;
				border-radius: 2px;
				content: '';
				width: .5em;
				height: .15em;
				display: block;
				transition: transform .14s;
				text-align: center;
				line-height: 1;
			}

			&:before {
				margin-left: -.25em;
				transform: rotate(45deg);
			}

			&:after {
				margin-top: -.15em;
				transform: rotate(-45deg);
			}
		}
	}

	li.open > .expand {
		&:before {
			transform: rotate(-45deg);
		}

		&:after {
			transform: rotate(45deg);
		}
	}

	.active > a {
		@if $menu-active-font-color {
			color: $menu-active-font-color;
		} @else {
			color: $theme-primary-color;
		}

		@if $menu-active-font-weight {
			font-weight: $menu-active-font-weight;
		}
	}

	ul {
		@include clear-ul;

		li {
			position: relative;

			ul {
				background: #fff;
				border: 1px solid #e5e5e5;
				box-shadow: 0 9px 10px rgba(0, 0, 0, 0.25);
				border-radius: 0 0 5px 5px;
				display: none;
				position: absolute;
				top: calc(100% - 1.263em);
				left: -.63em;

				&.open {
					display: block;
				}

				li {
					border: 1px solid #e5e5e5;
					border-width: 0 0 1px;
					min-width: 200px;

					&:last-child {
						border-bottom-width: 0;
					}
				}

				a {
					padding: .47em 1.105em;
				}
			}
		}
	}

	.level-1 {
		@include clearfix;

		> li {
			@include clearfix;
			float: left;
			white-space: nowrap;

			> a {
				float: left;
				padding: 1.74em;

				+ a {
					margin: 0 0 0 -.395em;
					padding: 2.42em 0.63em;
				}
			}

			.no-js &:hover ul {
				display: block;
			}
		}

		ul {
			clear: both;
		}
	}

	.menu-orientation-left &, .menu-orientation-right & {
		@media only screen and (min-width: $layout-width) {
			@include vertical-menu;
		}
	}

	.euphoria-menu & {
		@include vertical-menu;
	}

	.no-js & {
		@include breakpoint(tablet-portrait) {
			@include vertical-menu;
		}
	}
}

