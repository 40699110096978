@import "layout/header-cta";
@import "layout/header";
@import "layout/columns";
@import "layout/fluid-columns";
@import "layout/footer";
@import "layout/form";
@import "layout/region";
@import "layout/responsive-menu";

#content {
	background: #fff;
	margin-top: -1px;
}

.boxed {
	border: 1px solid #e3e3e3;
	border-radius: 5px;
	padding: 1.5em;
	margin-bottom: $column-gutter-bottom;
	overflow: hidden;

	&:last-child {
		margin-bottom: 0;
	}

	&.dark {
		background: #f8f8f8;
	}
}

.scroll-wrapper {
	overflow: hidden;
	position: relative;

	&:before, &:after {
		box-shadow: 0 0 20px 5px rgba(#000, .7);
		content: '';
		display: none;
		pointer-events: none;
		position: absolute;
	}

	&.more-content-right:after {
		display: block;
		top: 0; bottom: 0; right: 0;
	}

	&.more-content-left:before {
		display: block;
		top: 0; bottom: 0; left: 0;
	}

	.scroll-wrapper-inner {
		overflow: auto;
	}

	@include breakpoint(phone-landscape) {
		margin: 0 -1em;

		.scroll-wrapper-inner {
			padding: 0 1em;
		}
	}
}