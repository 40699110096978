.mod-detail {
	&.element-template--default, &.element-template--two-col, &.element-template--three-col {
		.item {
			@include clearfix;
			border: 1px solid #e7e7e7;
			border-radius: 5px;
			position: relative;
			overflow: hidden;
		}

		h3 a {
			color: $theme-primary-color;
			text-decoration: none;
		}

		time {
			display: block;
			font-weight: 700;
			margin-top: -.5em;
		}

		.image {
			background-size: cover;
			background-position: center;
			display: block;
		}

		.text {
			box-sizing: border-box;
			padding: 1.84em 2.63em;

			@include breakpoint(phone-landscape) {
				padding: 1.2em 1.4em 1.3em;
			}
		}
	}

	&.element-template--default {
		.item {
			margin-bottom: $column-gutter-bottom;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.image {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			width: 40%;
		}

		.text {
			float: left;
			width: 60%;
		}

		@include breakpoint(phone-landscape) {
			.image {
				position: static;
				height: 14em;
				width: auto;
			}

			.text {
				float: none;
				width: auto;
			}
		}
	}

	&.element-template--two-col, &.element-template--three-col {
		h3 {
			font-size: 1.31em;
		}

		.text {
			padding: 1.47em 1.68em;
		}
	}

	&.element-template--two-col {
		.image {
			height: 18em;
		}

		.items {
			@include basic-columns($count: 2, $selector: '.item', $breakpoint: phone-landscape);
		}

		.last-row-count-1 .item:nth-last-child(1) {
			margin-left: calc(25% + .25em);

			@include breakpoint(phone-landscape) {
				margin-left: 0;
			}
		}
	}

	&.element-template--three-col {
		.image {
			height: 14em;
		}

		.items {
			@include basic-columns($count: 3, $selector: '.item', $breakpoint: phone-landscape);
		}

		.last-row-count-2 .item:nth-last-child(2) {
			margin-left: calc(16.5% + .5em);

			@include breakpoint(phone-landscape) {
				margin-left: 0;
			}
		}

		.last-row-count-1 .item:nth-last-child(1) {
			margin-left: calc(33.5% + .25em);

			@include breakpoint(phone-landscape) {
				margin-left: 0;
			}
		}
	}
}
