.fluid-columns {
	box-sizing: border-box;
	display: table;
	min-height: 400px;
	min-height: 40vh;
	overflow: hidden;
	position: relative;
	table-layout: fixed;
	width: 100%;

	.column {
		display: table-cell;
		height: 100%;
		padding: 3em 0;
		position: relative;
		text-align: center;
		vertical-align: middle;

		@-moz-document url-prefix() {
			padding: 8em 0;
		}

		&.image {
			background: rgba(#333, .8);
		}

		&.text {
			padding: 8em 0;

			@include breakpoint(desktop) {
				padding: 6em 0;
			}

			.content {
				max-width: 580px;
				text-align: left;
			}
		}

		.fg-element {
			position: relative;
			z-index: 10;
		}

		.background .fg-element.empty {
			background: rgba(#fff, 0.5);
			min-height: 40px;
			margin: 10px;
		}

		&.text-overlay .image {
			opacity: .75;
		}

		// IE9+, fix for the background image not being centered properly and with the full height
		@media screen and (min-width:0\0) and (min-resolution: +72dpi) {
			.wrapper {
				height: 100%;
			}

			.background {
				height: 100%;
				float: left;
				max-height: 30px;
				margin-top: -30px;
			}
		}
	}

	&.one {
		min-height: 300px;
		min-height: 30vh;

		&:first-child {
			min-height: 600px;
			min-height: 50vh;
		}
	}

	&.two .column {
		width: 50%;

		.content {
			max-width: calc((50vw - ((100vw - #{$layout-width})/2)) - 2em);
		}

		&.image {
			width: 45%;
		}

		&.text {
			width: 55%;

			.content {
				max-width: calc((55vw - ((100vw - #{$layout-width})/2)) - 2em);
			}
		}
	}

	&.three .column {
		width: 33.333%;

		.content {
			max-width: .333 * $layout-width;
		}
	}

	@include phone-landscape {
		&.two, &.three {
			.column {
				display: table;
				min-height: 150px;
				overflow: hidden;
				width: 100% !important;
			}

			.text {
				padding: 3em 0;
			}

			.content {
				display: table-cell;
				vertical-align: middle;
				width: 100%;
			}
		}
	}
}

.column {

}