@import "../euphoria/buttons";
@import "../euphoria/responsive/breakpoints";

%body-font {
	color: $font-color;
	font: {
		family: $font-family;
		size: $font-size;
		weight: $font-weight;
	};

	@include breakpoint(tablet-portrait) {
		font-size: 16px;
	}
}

%body {
	line-height: 1.45;

	h1, h2, h3, h4, h5, h6 {
		@include breakpoint(tablet-portrait) {
			word-break: break-word;
		}
		@if $header-font-color {
			color: $header-font-color;
		}

		@if $header-font-family {
			font-family: $header-font-family;
		}

		@if $header-font-weight {
			font-weight: $header-font-weight;
		}

		line-height: 1.2;
		margin: .48em 0 .24em;

		+ p, + ol, + ul {
			margin-top: -.12em;
		}

		+ h1, + h2, + h3, + h4, + h5, + h6 {
			margin-top: -.14em;
		}
	}

	h1 {
		font-size: 1.842em;

		@if $h1-font-color {
			color: $h1-font-color;
		}

		@if $h1-font-weight {
			font-weight: $h1-font-weight;
		}
	}

	h2 {
		font-size: 1.684em;

		@if $h2-font-color {
			color: $h2-font-color;
		}

		@if $h2-font-weight {
			font-weight: $h2-font-weight;
		}
	}

	h3 {
		font-size: 1.526em;

		@if $h3-font-color {
			color: $h3-font-color;
		}

		@if $h3-font-weight {
			font-weight: $h3-font-weight;
		}
	}

	h4 {
		font-size: 1.316em;

		@if $h4-font-color {
			color: $h4-font-color;
		}

		@if $h4-font-weight {
			font-weight: $h4-font-weight;
		}
	}

	h5 {
		font-size: 1.052em;

		@if $h5-font-color {
			color: $h5-font-color;
		}

		@if $h5-font-weight {
			font-weight: $h5-font-weight;
		}
	}

	h6 {
		font-size: .947em;

		@if $h6-font-color {
			color: $h6-font-color;
		}

		@if $h6-font-weight {
			font-weight: $h6-font-weight;
		}
	}

	p {

	}

	h1, h2, h3, h4, h5, h6, p, ul, ol {
		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	ol, ul {
		padding-left: 1.8em;

		li {
			margin-bottom: .2em;
			line-height: 1.3;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	ol {
		counter-reset: section;

		li {
			position: relative;
		}

		li:before {
			counter-increment: section;
			content: counters(section, ".") ".";

			@if $list-ordered-icon-color {
				color: $list-ordered-icon-color;
			}

			position: absolute;
			top: 0;
			right: calc(100% + .4em);
		}
	}

	ul {
		li {
			position: relative;
			list-style-type: none;
		}

		li:before {
			color: $list-unordered-icon-color;
			content: '•';
			font-size: 1.3em;
			position: absolute;
			top: .04em;
			right: calc(100% + .3em);
			line-height: 1;
		}

		&.checks {
			li:before {
				display: none;
			}

			@if $list-unordered-checks-icon {
				list-style-image: $list-unordered-checks-icon;
			} @else {
				list-style-type: disc;
			}
		}
	}

	a {
		color: $link-color;
	}

	#{$all-buttons} {
		@include button;
		text-decoration: none !important;
	}

	em {

	}

	strong {
		font-weight: 700;
	}

	blockquote {
		cite {
		}
	}

	table {
		border-collapse: collapse;
		width: 100%;

		th, td {
			border: 1px solid #e3e3e3;
			padding: .7em 1.1em;
			text-align: left;

			@include breakpoint(phone-landscape) {
				padding: .5em .8em;
			}
		}

		th {
			background-color: $theme-secondary-color;
			border-color: $theme-secondary-color;
			color: set-color-for-background-color($theme-secondary-color);
		}
	}
}
