.header {
    left: 0;
    position: sticky;
    right: 0;
    top: 0;
    z-index: 99;
}

.header__top {
    padding: 12px 0;

    background: white;

    @media screen and (max-width: 1023px) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.14);
    }
}

.header__logo {
    max-width: 283px;
}

.header__content {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.header__checks {
    @media screen and (max-width: 1023px) {
        display: none;
    }
}

.header__bottom {
    background: $theme-primary-color;

    @media screen and (max-width: 1023px) {
        display: none;
    }
}

.header__menu {
    .mod-menu .level-1 > li {
        > a {
            position: relative;

            padding: 25px 20px;

            color: white;

            font-weight: 700;
        }

        &:hover,
        &.active {
            > a {
                color: $theme-secondary-color;
            }
        }

        &:first-child {
            > a {
                padding-left: 0;
            }
        }

        &:last-child {
            > a {
                padding-right: 0;
            }
        }
    }
}

.header__responsive-hamburger {
    display: none;

    .euphoria-menu-button {
        @include responsive-menu-button(
            $side: right,
            $style: square,
            $breakpoint: tablet-vertical,
            $color: $font-color,
            $active-color: set-color-for-background-color($theme-primary-color)
        );
    }

    @media screen and (max-width: 1023px) {
        display: block;
    }
}
