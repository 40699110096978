@import "../utils/clearfix";

$column-gutter: 1em !default;
$column-gutter-bottom: 2em !default;
$column-gutter-fallback: 5% !default;
$column-min-height: 1em !default;
$column-cleared-margin: 0 0 2em !default;
$column-selector: ".column" !default;

@mixin basic-column-container () {
	@include clearfix();
}

@mixin basic-column ( $float: left ) {
	box-sizing: border-box;
	float: $float;
	min-height: $column-min-height;
}

@mixin basic-column-clear ( $margin: $column-cleared-margin ) {
	float: none;

	&:nth-child(1n) {
		clear: none;
		margin: $margin;
		width: auto;
	}

	&:last-child {
		margin: 0;
	}
}

@mixin basic-columns-clear(
	$margin: $column-cleared-margin,
	$selector: $column-selector
) {
	@if & {
		$selector: "> " + $selector;
	}

	#{$selector} {
		@include basic-column-clear($margin);
	}
}

@mixin basic-columns (
	$count,
	$dimensions: null,
	$gutter: null,
	$breakpoint: null,
	$selector: $column-selector,
	$float: left,
	$gutter-bottom: $column-gutter-bottom
) {
	$original-selector: $selector;

	@if $gutter == null {
		$gutter: ( $column-gutter, $column-gutter-fallback );
	}

	@if type-of($gutter) == "number" {
		$gutter: ( $column-gutter * $gutter, $column-gutter-fallback * $gutter );
	}

	$gutter-fallback: if( length($gutter) > 1, nth( $gutter, 2 ), $gutter );
	$gutter: nth($gutter, 1);

	@if & {
		@include basic-column-container;
		$selector: "> " + $selector;
	}

	@if $dimensions == null {
		$dimensions: 100% / $count;
	}

	#{$selector} {
		@include basic-column($float);

		&:nth-child(1n) {
			@if $float == left {
				margin: 0 $gutter-fallback $gutter-bottom 0;
				margin-right: calc(#{$gutter});
			}

			@if $float == right {
				margin: 0 0 $gutter-bottom $gutter-fallback;
				margin-left: calc(#{$gutter});
			}
		}

		@for $i from 1 through $count {
			&:nth-child( #{$count}n+#{$i} ) {
				$width: if( length($dimensions) >= $i, nth( $dimensions, $i ), nth($dimensions, 1));
				$width-gutter: ceil((($gutter * ($count - 1))/$count)*100)/100;
				
				width: $width - (($gutter-fallback*($count - 1))/$count);
				width: calc( #{$width} - #{$width-gutter} );
			}

			&:nth-last-child( #{$i} ) {
				margin-bottom: 0;
			}
		}

		&:nth-child( #{$count}n+1 ) {
			clear: both;
		}

		&:nth-child( #{$count}n+#{$count} ) {
			@if $float == left {
				margin-right: 0;
			}

			@if $float == right {
				margin-left: 0;
			}
		}

		&:nth-child(1n) {
			@content;
		}
	}

	@if $breakpoint != null {
		@include breakpoint( $breakpoint ) {
			@include basic-columns-clear($selector: $original-selector);
		}
	}
}

@mixin basic-two-columns ( $left-width: 50%, $right-width: 50%, $gutter: null, $breakpoint: null, $float: left, $gutter-bottom: $column-gutter-bottom ) {
	@include basic-columns( 2, ($left-width, $right-width), $gutter, $breakpoint, $float: $float, $gutter-bottom: $gutter-bottom);
}
