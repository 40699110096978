.button {
    .header__bottom & {
        padding-bottom: 10px;
        padding-top: 10px;

        background-color: white;
        border: 2px solid white;
        color: $theme-primary-color;

        font-weight: 700;

        &:hover {
            background-color: $theme-primary-color;
            color: white;
        }

        &:first-of-type {
            margin-right: .5em;
        }
    }
}

:root .button--has-tel {
    padding-left: 35px;
}

.button--has-tel {
    position: relative;

    &:before {
        position: absolute;
        left: 10px;
        top: 50%;

        content: "";
        height: 22px;
        width: 21px;

        background: url(../images/phone-white.svg) no-repeat center;
        background-size: cover;
        transform: translateY(-50%);
    }

    .header__bottom & {
        &:before {
            background-image: url(../images/phone-primary.svg);
            background-size: cover;
        }

        &:hover {
            &:before {
                background-image: url(../images/phone-white.svg);
                background-size: cover;
            }
        }
    }

}
