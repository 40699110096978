.mod-contact-form {
	text-align: left;

	.form-error {
		margin-bottom: 1em;
	}

	.form-field {
		max-width: 540px;
	}

	.form-field-textarea {
		max-width: none;
	}

	&.element-template--one-line {
		position: relative;

		.form-field {
			max-width: none;

			.error {
				display: none;
			}
		}

		input[type="text"], input[type="password"], input[type="email"], textarea, select {
			border: none;
			border-radius: 3px;
			padding: .9333em 1em;
		}

		button[type="submit"] {
			position: absolute;
			bottom: .26em;
			right: .26em;
			padding: .48em .84em;
		}

		.some-fields-required {
			display: none;
		}
	}
}
