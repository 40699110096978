@import "../../euphoria/layout/columns";

.columns {
	&.two {
		@include basic-columns(
			$count: 2,
			$breakpoint: phone-landscape
		);
	}

	&.two-uneven {
		&.wide-slim {
			@include basic-columns(
				$count: 2,
				$dimensions: (67%, 33%),
				$breakpoint: tablet-portrait
			);
		}

		&.slim-wide {
			@include basic-columns(
				$count: 2,
				$dimensions: (33%, 67%),
				$breakpoint: tablet-portrait
			);
		}

		&.superslim-wide {
			@include basic-columns(
				$count: 2,
				$dimensions: (24%, 76%),
				$breakpoint: tablet-portrait
			);
		}

		&.wide-superslim {
			@include basic-columns(
				$count: 2,
				$dimensions: (76%, 24%),
				$breakpoint: tablet-portrait
			);
		}
	}

	&.three {
		@include basic-columns(
			$count: 3,
			$breakpoint: phone-landscape
		);
	}

	&.four {
		@include basic-columns(
			$count: 4,
			$breakpoint: tablet-portrait
		);

		@include breakpoint(tablet-portrait) {
			@include basic-columns(
				$count: 2,
				$breakpoint: phone-landscape
			);
		}
	}
}
