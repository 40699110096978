$max-width: calc((55vw - ((100vw - #{$layout-width}) / 2)) - 3em);

.full-width-2-col, .full-width-2-col-alt {
	&.fluid-columns.two .text .content {
		max-width: $max-width;

		.fg-element {
			width: 100%;
		}

		@media only screen and (max-width: $layout-width) {
			float: none !important;
		}

		@media only screen and (min-width: $layout-width + 1px) {
			max-width: none;
			width: $max-width;
		}

		@include breakpoint(phone-landscape) {
			float: none;
		}
	}
}

.full-width-2-col {
	.text .content {
		float: left;
		padding-left: 3em;

		@include breakpoint(phone-landscape) {
			padding-left: 1em;
		}
	}
}

.full-width-2-col-alt {
	.text .content {
		float: right;
		padding-right: 3em;

		@include breakpoint(phone-landscape) {
			padding-right: 1em;
		}
	}
}

.full-width-2-col + .full-width-2-col,
.full-width-2-col + .full-width-2-col-alt,
.full-width-2-col-alt + .full-width-2-col,
.full-width-2-col-alt + .full-width-2-col-alt {
	margin-top: -$fragment-spacing;
}

.full-width-2-col, .full-width-2-col-alt {
	&.dark {
		background: #f6f6f6;
	}
}