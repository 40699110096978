/*
 *  Owl Carousel - Animate Plugin
 */
@keyframes slider--fade-out {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

.element-template--slider, .element-template--slider-big, .element-template--slider-huge {
	background: rgba(#333, .8);
	position: relative;

	.full-width & {
		border-bottom: 5px solid $theme-primary-color;
	}

	.slide {
		background-position: center;
		background-size: cover;
		display: table;
		min-height: 13.16em;
		/*position: absolute;*/
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		width: 100%;

		&:nth-child(n+2) {
			display: none;
		}

		.text-wrapper {
			display: table-cell;
			vertical-align: middle;
			text-align: center;
		}

		.text {
			background: rgba($theme-primary-color, .9);
			border-radius: 5px;
			color: #fff;
			display: inline-block;
			font-size: 1.84em;
			font-weight: 700;
			height: auto;
			padding: .44em .8em;

			h1, h2, h3, h4, h5, h6 {
				color: inherit;
			}
		}
	}

	.js.effects-intro & {
		&.owl-loaded .owl-item:first-child, &:not(.owl-loaded) .slide:first-child {
			.effect-wrapper {
				opacity: 0;
			}
		}
	}

	.fluid-columns & {
		border: none;
		height: 100%;
		position: absolute !important;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		div {
			height: 100%;
		}

		.slide {
			min-height: 0;
		}
	}
}

.mod-detail {
	&.element-template--slider, &.element-template--slider-big, &.element-template--slider-huge {
		position: relative;

		.fg-edit-controls {
			position: absolute;
			top: .3em;
			left: .3em;
			z-index: 10;
		}

		.slide {
			.text-wrapper {
				text-align: left;
				padding: 2em 0;
			}

			.text {
				box-sizing: border-box;
				font-size: 1.2em;
				padding: 1.2em 1.6em;
				max-width: 850px;

				@include breakpoint(phone-landscape) {
					font-size: 1em;
				}
			}
		}
	}
}

.element-template--slider-big .slide {
	min-height: 26.316em;
}

.element-template--slider-huge .slide {
	min-height: 40em;

	@include breakpoint(tablet-landscape) {
		min-height: 26.316em;
	}
}

.element-template--slider, .element-template--slider-big, .element-template--slider-huge {
	visiblity: hidden;
	width: 100%;
	-webkit-tap-highlight-color: transparent;
	/* position relative and z-index fix webkit rendering fonts issue */
	position: relative;
	z-index: 1;

	&.owl-loading {
		opacity: 0;
		visibility: visible;
	}

	&.owl-loaded {
		visibility: visible;

		.owl-item:nth-child(n+2) {
			display: block;
		}
	}

	&.owl-hidden {
		opacity: 0;
	}

	&.owl-refresh .owl-item {
		display: none;
	}

	&.owl-drag .owl-item {
		user-select: none;
	}

	&.owl-grab {
		cursor: grab;
	}

	&.owl-rtl {
		direction: rtl;

		.owl-item {
			float: right;
		}
	}

	.owl-nav {
		.owl-prev, .owl-next {
			height: 3em;
			width: 1.5em;

			position: absolute;
			top: 50%;
			margin-top: -1.5em;
			opacity: 1;
			transition: opacity .14s;

			&:before, &:after {
				background: #fff;
				border-radius: 40px;
				content: '';
				width: 0.44em;
				height: 1.92em;
				display: block;
				transition: transform .14s, height .14s;
				text-align: center;
				line-height: 1;
				position: absolute;
				right: .75em;
			}

			&:before {
				top: 0;
			}

			&:after {
				bottom: 0;
			}

			&:active {
				&:before, &:after {
					height: 1.74em;
				}
			}

			&.disabled {
				opacity: 0;
			}
		}

		.owl-prev {
			left: 1em;

			&:before {
				transform: rotate(45deg);
			}

			&:after {
				transform: rotate(-45deg);
			}

			&:active {
				&:before {
					transform: rotate(30deg);
				}

				&:after {
					transform: rotate(-30deg);
				}
			}
		}

		.owl-next {
			right: 1em;

			&:before {
				transform: rotate(-45deg);
			}

			&:after {
				transform: rotate(45deg);
			}

			&:active {
				&:before {
					transform: rotate(-30deg);
				}

				&:after {
					transform: rotate(30deg);
				}
			}
		}
	}

	.fadeOut {
		animation-name: slider--fade-out;
	}

	.animated {
		animation-duration: 1000ms;
		animation-fill-mode: both;
	}

	.owl-animated-in {
		z-index: 0;
	}

	.owl-animated-out {
		z-index: 1;
	}

	.owl-height {
		transition: height 500ms ease-in-out;
	}

	.owl-stage {
		position: relative;
		-ms-touch-action: pan-Y;
	}

	.owl-stage:after {
		content: ".";
		display: block;
		clear: both;
		visibility: hidden;
		line-height: 0;
		height: 0;
	}

	.owl-stage-outer {
		position: relative;
		overflow: hidden;
		/* fix for flashing background */
		-webkit-transform: translate3d(0px, 0px, 0px);
	}

	.owl-item {
		position: relative;
		min-height: 1px;
		float: left;
		backface-visibility: hidden;
		-webkit-tap-highlight-color: transparent;
		-webkit-touch-callout: none;

		img {
			display: block;
			width: 100%;
			-webkit-transform-style: preserve-3d;
		}
	}

	.owl-nav, .owl-dots {
		&.disabled {
			display: none;
		}

		.owl-prev, .owl-next, .owl-dot {
			cursor: pointer;
			user-select: none;
		}
	}

	.owl-item {
		.owl-lazy {
			opacity: 0;
			transition: opacity 400ms ease;
		}

		img {
			transform-style: preserve-3d;
		}
	}

	.owl-video-wrapper {
		position: relative;
		height: 100%;
		background: #000;
	}

	.owl-video-play-icon {
		position: absolute;
		height: 80px;
		width: 80px;
		left: 50%;
		top: 50%;
		margin-left: -40px;
		margin-top: -40px;
		//background: url("owl.video.play.png") no-repeat;
		cursor: pointer;
		z-index: 1;
		backface-visibility: hidden;
		transition: scale 100ms ease;

		&:hover {
			transition: scale(1.3, 1.3);
		}
	}

	.owl-video-playing {
		.owl-video-tn, .owl-video-play-icon {
			display: none;
		}
	}

	.owl-video-tn {
		opacity: 0;
		height: 100%;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: contain;
		transition: opacity 400ms ease;
	}

	.owl-video-frame {
		position: relative;
		z-index: 1;
		height: 100%;
		width: 100%;
	}
}
