.checklist {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        position: relative;

        display: flex;
        flex-wrap: wrap;
        margin-right: 40px;
        padding: 0 0 0 35px;

        &:before {
            left: 0;
            position: absolute;
            top: 0;

            content: '';
            display: block;
            flex: 0 0 24px;
            height: 24px;
            margin-right: 1em;
            width: 24px;

            background: url(../images/check.svg) no-repeat center;
            background-size: contain;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }
}
